* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  border: 0;
  font-family: "Arial Black", Gadget, sans-serif, Helvetica Bold;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

body {
  background-color: #411f4d;
  min-height: 100%;
  min-height: -moz-available;
  min-height: -webkit-fill-available;
  min-height: fill-available;
}

html,
body {
  height: 100%;
  width: 100%;
  position: fixed;
  overflow: hidden;
  color: #fff;
}

#crosshairs {
  position: absolute;
  width: 50px;
  height: 50px;
  z-index: 3;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(ab77a2cadeeb3632461b1d8e9d791db5.png);
  image-rendering: crisp-edges;
  image-rendering: pixelated;
}

#join-or-die {
  position: absolute;
  display: block;
  opacity: 0.5;
  margin: auto;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url(f043169d8f9cdad5c61e0f67ad9631cc.png);
}

.heart {
  width: 100%;
  height: 100%;
  width: 50px;
  height: 50px;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url(dc2391ff9c640b974018e035c62c778d.png);
}

.join-or-die-wrapper {
  position: absolute;
  display: flex;
  z-index: 1;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 640px;
  min-height: 150px;
}

#game {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  image-rendering: crisp-edges;
  image-rendering: pixelated;
}

#audio-toggle:hover {
  cursor: pointer;
}

.bar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
}

.bottom {
  width: 100%;
  position: absolute;
  z-index: 3;
  bottom: 10px;
}

.top {
  width: 100%;
  position: absolute;
  z-index: 3;
  top: 10px;
}

a {
  color: #fff;
  text-decoration: none;
}

a:link {
  color: #fff;
}

a:visited {
  color: #fff;
}

a:focus {
  color: #fff;
}

a:hover {
  color: #fff;
}

a:active {
  color: #fff;
}
